<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="600px" persistent>
      <v-card>
        <v-toolbar card prominent top offet-y style="z-index: 2;">
          <v-toolbar-title class="headline grey-text">Currency Conversion</v-toolbar-title>
          <v-spacer></v-spacer>
          <!--<v-btn icon @click.native="dialog = false">
            <v-icon color="green">fas fa-check</v-icon>
          </v-btn>-->
          <v-btn icon @click.native="dialog = false">
            <v-icon color="red">fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-btn v-on:click.prevent="checkNewConversion($v.currentConversion)"
                 v-if="newConversion"
                 style="margin-left: 10px;"
                 slot="activator"
                 fab
                 flat
                 middle
                 right
                 color="green">
            <v-icon>far fa-save</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="!newConversion" v-on:click.prevent="createNewConversion()"
                 slot="activator"
                 small
                 fab
                 flat
                 middle
                 right>
            <v-icon>fas fa-plus</v-icon>
          </v-btn>
          <v-btn v-if="newConversion" v-on:click.prevent="closeNewConversionWindow()"
                 slot="activator"
                 fab
                 flat
                 middle
                 right
                 color="red">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text style="height: 400px;">

          <v-layout wrap v-if="newConversion">
            <v-flex xs12>
              <v-autocomplete v-bind:items="currencies"
                              label="Convert From"
                              v-model="currentConversion.convertFrom"
                              :error-messages="covertFromErrors"
                              @input="$v.currentConversion.convertFrom.$touch()"
                              @blur="$v.currentConversion.convertFrom.$touch()"
                              item-text="currencyName"
                              item-value="currencyShort">
                <template slot="selection"
                          slot-scope="data">
                  <v-chip :selected="data.selected"
                          class="chip--select-multi">
                    {{ data.item.currencyName }}
                  </v-chip>
                </template>
                <template slot="item"
                          slot-scope="data">

                  <template>
                    <v-list-tile-content>
                      <v-list-tile-title v-html="data.item.currencyName"></v-list-tile-title>
                      <v-list-tile-sub-title v-html="data.item.currencyShort"></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-autocomplete v-bind:items="currencies"
                              label="Convert To"
                              v-model="currentConversion.convertTo"
                              :error-messages="convertToErrors"
                              @input="$v.currentConversion.convertTo.$touch()"
                              @blur="$v.currentConversion.convertTo.$touch()"
                              item-text="currencyName"
                              item-value="currencyShort">
                <template slot="selection"
                          slot-scope="data">
                  <v-chip :selected="data.selected"
                          class="chip--select-multi">
                    {{ data.item.currencyName }}
                  </v-chip>
                </template>
                <template slot="item"
                          slot-scope="data">

                  <template>
                    <v-list-tile-content>
                      <v-list-tile-title v-html="data.item.currencyName"></v-list-tile-title>
                      <v-list-tile-sub-title v-html="data.item.currencyShort"></v-list-tile-sub-title>
                    </v-list-tile-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-flex>
            <v-flex xs12>
              <v-text-field label="Conversion Rate"
                            v-model="currentConversion.conversionRate"
                            :error-messages="conversionRateErrors"
                            @input="$v.currentConversion.conversionRate.$touch()"
                            @blur="$v.currentConversion.conversionRate.$touch()"></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-menu ref="menu1"
                      :close-on-content-click="false"
                      v-model="menu1"
                      :nudge-right="40"
                      lazy
                      transition="scale-transition"
                      offset-y
                      full-width
                      max-width="290px"
                      min-width="290px">
                <v-text-field slot="activator"
                              v-model="currentConversion.date"
                              label="Date"></v-text-field>
                <v-date-picker v-model="currentConversion.date" no-title @input="menu1 = false"></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="listConversion">
            <v-list two-line style="width: 100%;">
              <v-divider></v-divider>
              <template v-for="(item, index) in conversions">


                <v-list-tile :key="item.id"
                             @click="returnSelectedConversion(item)">

                  <v-list-tile-content>
                    <v-list-tile-sub-title>
                      <v-layout wrap>
                        <v-flex xs6>
                          Convert From: {{item.convertFrom}}
                        </v-flex>
                        <v-flex xs6>
                          Convert To: {{item.convertTo}}
                        </v-flex>
                      </v-layout>
                    </v-list-tile-sub-title>
                    <v-list-tile-title>Rate: {{item.conversionRate}}</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider></v-divider>
              </template>
            </v-list>
          </v-layout>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
  import { validationMixin } from 'vuelidate'
  import { required } from 'vuelidate/lib/validators'

  export default {
    mixins: [validationMixin],
    data() {
      return {
        dialog: false,
        menu1: '',
        newConversion: false,
        listConversion: true,
        currencies: [],
        currentConversion: {
          convertFrom: '',
          convertTo: '',
          conversionRate: '',
          date: ''
        },
        conversions: [],
        items: []
      }
    },
    computed: {
      covertFromErrors() {
        const errors = []
        if (!this.$v.currentConversion.convertFrom.$dirty) return errors
        !this.$v.currentConversion.convertFrom.required && errors.push('Convert From is required')
        return errors
      },
      convertToErrors() {
        const errors = []
        if (!this.$v.currentConversion.convertTo.$dirty) return errors
        !this.$v.currentConversion.convertTo.required && errors.push('Convert To is required')
        return errors
      },
      conversionRateErrors() {
        const errors = []
        if (!this.$v.currentConversion.conversionRate.$dirty) return errors
        !this.$v.currentConversion.conversionRate.required && errors.push('Convert To is required')
        return errors
      }
    },
    methods: {
      showConverter: function () {
        this.dialog = true
      },
      createNewConversion: function () {
        this.newConversion = true
        this.listConversion = false
      },
      conversionCheck: function ($v) {
        $v.$touch(); //Triggers Error Messages

        if ($v.convertFrom.$invalid || $v.convertTo.$invalid || $v.conversionRate.$invalid) {
          return false
        }

        return true
      },
      checkNewConversion: function ($v) {
        if (this.conversionCheck($v)) {
          this.addConversion($v)
        }
      },
      addConversion: function ($v) {
        let newEntry = JSON.parse(JSON.stringify(this.currentConversion))
        
        newEntry.id = this.getLargestIdFromConversions() + 1

        this.$store.commit('addNewCurrencyConversionToStore', newEntry)

        this.clearNewConversionAndReset($v)
        this.returnSelectedConversion(newEntry)
      },
      clearNewConversionAndReset: function($v) {
        this.currentConversion.convertFrom = ''
        this.currentConversion.convertTo = ''
        this.currentConversion.conversionRate = ''
        this.currentConversion.date = ''

        $v.$reset()

        this.closeNewConversionWindow()
      },
      closeNewConversionWindow: function() {
        this.newConversion = false
        this.listConversion = true
      },
      getLargestIdFromConversions: function () {
        let largest = 0
        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].id > largest) {
            largest = this.items[i].id
          }
        }
        return largest
      },
      getcurrencyData: function (from, to) {
        this.currentConversion.convertFrom = from
        this.currentConversion.convertTo = to

        this.showDataForCurrencies(from, to)
      },
      showDataForCurrencies: function(from, to) {
        this.getCurrencyConversions()

        this.conversions = []

        for (let i = 0; i < this.items.length; i++) {
          if (this.items[i].convertFrom === from && this.items[i].convertTo === to) {
            this.conversions.push(this.items[i])
          }
        }
      },
      getCurrencyConversions: function() {
        this.items = this.$store.state.CurrencyConversions
      },
      returnSelectedConversion: function(item) {
        this.$emit('conversionRate', item)
        this.dialog = false
      },
      getAvailableCurriencies: function () {
        let cur = this.$store.state.Currencies

        for (let i = 0; i < cur.length; i++) {
          if (cur[i].ObjectState !== "Delete") {
            this.currencies.push(cur[i])
          }
        }
      }
    },
    beforeMount() {
      this.getAvailableCurriencies()
    },
    validations: {
      currentConversion: {
        convertFrom: {
          required
        },
        convertTo: {
          required
        },
        conversionRate: {
          required
        }
      }
    }
      
    
  }

</script>
